
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.1);
    background-color: #1f78a7;
    color: #fff;
    border-radius: 5px;
}
.mainTitle {
    @apply text-3xl;
    @include xxl {
        @apply text-4xl;
    }
}
